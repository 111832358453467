@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
}

/* .heading1 {
  font-size: 1.25rem;
  font-size: clamp(1.25rem, 0.89rem + 1.76vw, 2rem);
} */

.heading1 {
  font-size: 1rem;
  font-size: clamp(1rem, 0.728813559322034rem + 1.3559322033898304vw, 2rem);
}

/* 14px 24px 320px 1440px */
.heading3 {
  font-size: 0.875rem;
  font-size: clamp(
    0.875rem,
    0.6964285714285714rem + 0.8928571428571428vw,
    1.5rem
  );
}

/* 12px 16px 320px 1440px */
.typography-2 {
  font-size: 0.75rem;
  font-size: clamp(
    0.75rem,
    0.6785714285714286rem + 0.35714285714285715vw,
    1rem
  );
}

.inter-font {
  font-family: "Inter", sans-serif;
}

/* 640px 1440px */

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

.data-grid-div {
  /* width: calc(100% - 340px) !important; */
  width: calc(100vw - 400px) !important;
}

.superadmin-data-grid-div {
  width: calc(100vw - 200px) !important;
}
